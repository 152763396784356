<template>
  <div>
    <el-dialog
        title="码头信息"
        :visible.sync="dialogVisible"
        @close="dialogVisible = false"
        append-to-body
        center
        width="40%"
    >

      <div
          v-if="status===1||(data_list&&data_list.length!==1||!data_list||!inputShip.otherCode||!(inputShip.otherCode.toUpperCase()===data_list[0].wharfCode.toUpperCase()))"
      >
        <p class="tip_span_title">
          请检查码头是否正确！</p>
        <p class="tip_span">
          请检查船名航次是否填写有误、是否有多余空格、是否发送过早或者港区更新不及时，确认无误可直接保存</p>
      </div>
      <p class="tip_span2" v-if="data_list&&data_list.length!==0">目前查询到的码头如下</p>
      <el-table v-if="data_list&&data_list.length!==0"
                :data="data_list" stripe border
                :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
      >
        <el-table-column
            v-for="item in tableHeaders"
            :prop="item.prop"
            :label="item.label"
            :width="item.width?item.width:'auto'"
            :key="item.prop"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'voyage'">
              <p
                  :class="inputShip.voyage===scope.row[item.prop]?'':'sys_voyage_span'">{{
                  (inputShip.voyage === scope.row[item.prop] ? '' : '系统航次：') + scope.row[item.prop]
                }}
              </p>
              <p v-if="inputShip.voyage!==scope.row[item.prop]" class="user_voyage_span">您的航次：{{
                  inputShip.voyage
                }}</p>

            </div>
            <div v-else-if="item.prop === 'wharf'">
              <p
                  :class="inputShip.otherCode&&inputShip.otherCode.toUpperCase()===scope.row['wharfCode'].toUpperCase()?'':'sys_voyage_span'">
                {{
                  (inputShip.otherCode&&inputShip.otherCode.toUpperCase() === scope.row['wharfCode'].toUpperCase() ? '' : '系统码头：') + scope.row[item.prop]
                }}
              </p>
              <p v-if="inputShip.otherCode&&inputShip.otherCode.toUpperCase()!==scope.row['wharfCode'].toUpperCase()"
                 class="user_voyage_span">您的码头：{{
                  inputShip.wharf
                }}</p>

            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>

        </el-table-column>
        <slot name="todo">
          <el-table-column label="操作" width="80" align="center">
            <template slot-scope="scope">
              <div>
                <span class="update_span" @click="operator('changeWharf',scope.row)">选择</span>
              </div>
            </template>
          </el-table-column>
        </slot>
      </el-table>
      <span slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="operator('sure')"
                             v-if="status===1||(data_list&&data_list.length!==1||!data_list)">确认无误</el-button>

                  <el-button @click="dialogVisible = false">
                      取 消
                  </el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      tableHeaders: [
        {
          prop: "wharf",
          label: "停靠码头",
          width: 180,
        },
        {
          prop: "vesselsName",
          label: "船名",
          width: 'auto',
        },
        {
          prop: "voyage",
          label: "航次",
          width: 180,
        },
        // {
        //   prop: "shipCompanyName",
        //   label: "船公司",
        //   width: 100,
        // },
        // {
        //   prop: "shipAgentName",
        //   label: "船代",
        //   width: 100,
        // },
      ],
      dialogVisible: false,
      data_list: [],
      inputShip: {//用户输入的船名航次，用于和结果对比
        vessels: '',
        voyage: '',
        wharf: '',
        wharfCode: '',
        otherCode: '',
      },
      status: 0,
    };
  },
  methods: {
    init(userShip, data, status = 0) {
      this.dialogVisible = true;
      this.status = status;
      if (data) {
        this.data_list = data;
      }
      if (userShip) {
        this.inputShip = userShip;
      }
    },
    resetInfo() {
      this.dialogVisible = false;
      this.data_list.length = 0;
      this.inputShip = {
        vessels: '',
        voyage: '',
        wharf: '',
      }
    },
    operator(type, item) {//edit,delete,copy
      this.$emit(type, item);
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="stylus" scoped>
.update_span {
  color: blue;
  cursor: pointer;
  //margin-right: 1.5rem;
}

.sys_voyage_span {
  color: blue;
  font-size: 14px;
  margin: 0;
  //margin-right: 1.5rem;
}

.user_voyage_span {
  margin: 0;
  color: var(--RED-F21137);
  font-size: 14px;
  //margin-right: 1.5rem;
}

.tip_span_title {
  color: var(--RED-F21137);
  margin: 0 0 0.5rem 0;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.5;
  text-align: center;
}

.tip_span {
  color: var(--RED-F21137);
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 2rem 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
}

.tip_span2 {
  color: var(--RED-F21137);
  margin: 0 0 1rem 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
}
</style>
